import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Row, Col, Button, Modal, Input, notification } from 'antd';

import Recaptcha from 'react-google-recaptcha';
import firebase from 'gatsby-plugin-firebase';

import moment from 'moment';
import ls from 'local-storage';
import { navigate } from 'gatsby';

const StyledDiv = styled.div`
  margin-top: 64px;
  min-height: calc(100vh - 72px - 64px);
  background-color: #f2f2f5;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .option-card {
    border-radius: 10px;

    h4 {
      font-size: 1.5rem;
    }

    background-color: white;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.3);

    > div {
      border-radius: 5px;
      min-height: 300px;
      padding: 25px;
      display: flex;
      align-items: flex-end;
    }
  }

  .buyer {
    background-image: url('../bg-buyer.jpg');
    > div {
      background-color: rgba(0, 51, 102, 0.88);
      color: white;
    }

    .ant-btn-primary {
      color: #003366;
      background-color: #fad127;
      border-color: #fad127;
    }
    :hover {
      opacity: 0.9;
    }
  }

  .seller {
    background-image: url('../bg-seller.jpg');
    > div {
      background-color: rgba(250, 209, 39, 0.88);
    }
    .ant-btn-primary {
      color: #fad127;
      background-color: #003366;
      border-color: #003366;
      :hover {
        opacity: 0.9;
      }
    }
  }

  .buyer,
  .seller {
    background-size: cover;
    background-position: center;
  }
`;

function IndexPage() {
  const [recaptcha, setRecaptcha] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [isRecaptchaErr, setIsRecaptchaErr] = useState(false);
  const [isAccessCodeError, setIsAccessCodeError] = useState('');

  const [showModal, setShowModal] = useState(false);
  const recaptchaRef = useRef(null);

  const [isCodeLoading, setIsCodeLoading] = useState(false);

  function authenticateAccessCode(needsModal = false, storedCode = '') {
    setIsCodeLoading(true);
    const aCode = storedCode ? storedCode : accessCode;
    firebase
      .database()
      .ref(`access_codes/${aCode.trim()}`)
      .once('value')
      .then((snapshot) => {
        if (snapshot.val()) {
          if (snapshot.val().status === 'active') {
            ls.set('access_code', aCode);
            ls.set('expires_in', moment().add(1, 'd'));
            navigate('/home');
          } else {
            if (needsModal) setShowModal(true);
            else {
              notification.warning({
                message: 'Something went wrong',
                description: `The code you used is no longer active or expired. Contact our team to help you this issue.`,
              });
            }
          }
        } else {
          if (needsModal) setShowModal(true);
          else {
            notification.error({
              message: 'Invalid Access Code',
              description: `The code you used is doesn't exist in our database.`,
            });
          }
        }
      })
      .catch((onErr) => {
        notification.error({
          message: 'Something went wrong!',
          description: `We are experiencing some issues. ${JSON.stringify(
            onErr
          )}`,
        });
      })
      .finally(() => {
        setIsCodeLoading(false);
      });
  }

  function handleAccessRequest() {
    let errorCount = 0;
    if (!recaptcha) {
      setIsRecaptchaErr(true);
      errorCount++;
    } else {
      setIsRecaptchaErr(false);
    }

    if (!accessCode.trim()) {
      setIsAccessCodeError('Please provide your access code!');
      errorCount++;
    } else {
      setIsAccessCodeError('');
    }

    if (errorCount === 0) {
      authenticateAccessCode();
    } else {
      notification.warning({
        message: 'Incomplete fields!',
        description: 'Please complete all required fields to proceed.',
      });
    }
  }

  return (
    <Layout>
      <SEO title="Home" />
      <Modal
        destroyOnClose
        visible={showModal}
        closable={false}
        maskClosable={false}
        onOk={handleAccessRequest}
        okText="ACCESS PORTAL"
        okButtonProps={{
          loading: isCodeLoading,
          style: {
            backgroundColor: '#003366',
          },
        }}
        cancelText="CANCEL"
        onCancel={() => {
          setShowModal(false);
          setIsAccessCodeError('');
          setIsRecaptchaErr(false);
        }}
      >
        <p style={{ fontSize: '1.1em' }}>
          To view our listings you will be needing an access code provided by
          our team. If you don't have access code, you request access by
          clicking this{' '}
          <a href="/request-portal-access" target="_blank">
            link
          </a>
          .
        </p>

        <div style={{ marginBottom: 16 }}>
          <b>ENTER YOUR ACCESS CODE:</b>
          <Input
            placeholder="e.g 1hJrAhoqGXWUmwpwDmuV"
            style={{ margin: '4px 0px' }}
            onChange={(e) => {
              setAccessCode(e.target.value);
            }}
            asdasdasdasd={accessCode}
          />
          {isAccessCodeError ? (
            <div
              style={{
                color: '#ff4d4f',
                fontSize: 14,
                marginTop: 4,
                marginBottom: -8,
              }}
            >
              {isAccessCodeError}
            </div>
          ) : null}
        </div>

        <Recaptcha
          ref={recaptchaRef}
          sitekey="6LdehiUTAAAAALj4VhxGS0EEMsC6aech1yN8UFfk"
          onChange={(value) => {
            setRecaptcha(value);
          }}
        />
        {isRecaptchaErr ? (
          <div
            style={{
              color: '#ff4d4f',
              fontSize: 14,
              marginTop: 4,
              marginBottom: -8,
            }}
          >
            Prove you're a human by completing the recaptcha.
          </div>
        ) : null}
      </Modal>

      <StyledDiv>
        <Row type="flex" justify="center">
          <Col
            xs={{ span: 20 }}
            sm={{ span: 18 }}
            md={{ span: 9 }}
            lg={{ span: 9 }}
            xl={{ span: 8 }}
          >
            <div className="option-card buyer">
              <div>
                <div>
                  <h4>Are you looking for a property for your investment?</h4>
                  <p>
                    We have properties you might be interested for your next
                    project and future expansion of your business.
                  </p>
                  <Button
                    type="primary"
                    onClick={() => {
                      const tempAccessCode = ls.get('access_code');
                      const tempExpiresIn = ls.get('expires_in');
                      if (tempAccessCode === null || tempExpiresIn === null) {
                        setShowModal(true);
                      } else {
                        if (tempAccessCode) {
                          if (tempExpiresIn) {
                            if (!moment().isAfter(moment(tempExpiresIn))) {
                              authenticateAccessCode(true, tempAccessCode);
                            } else {
                              ls.remove('access_code');
                              ls.remove('expires_in');
                              setShowModal(true);
                            }
                          } else {
                            ls.remove('access_code');
                            ls.remove('expires_in');
                            setShowModal(true);
                          }
                        } else {
                          ls.remove('access_code');
                          ls.remove('expires_in');
                          setShowModal(true);
                        }
                      }
                    }}
                  >
                    <b>EXPLORE PRIME PROPERTIES</b>
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 0 }}
            lg={{ span: 0 }}
            xl={{ span: 0 }}
          >
            <br></br>
          </Col>

          <Col
            xs={{ span: 20 }}
            sm={{ span: 18 }}
            md={{ span: 9, offset: 1 }}
            lg={{ span: 9, offset: 1 }}
            xl={{ span: 8, offset: 1 }}
          >
            <div className="option-card seller">
              <div>
                <div>
                  <h4>Are you looking for a partner to sell your property?</h4>
                  <p>
                    Partnering with PRIME will help you get the best value of
                    your property.
                  </p>
                  <Button type="primary" href="/partner-with-prime">
                    <b>PARTNER WITH US</b>
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </StyledDiv>
    </Layout>
  );
}

export default IndexPage;
